<template>
  <div class="steps__card">
    <img
      src="@/assets/ico/onboarding/chip.png"
      alt=""
      class="steps__card__chip"
    />
    <div
      class="steps__card__text mt-6 w-3/6 m-auto -mb-2 text-left text-grey-3"
    >
      <div class="text-xs">{{ $t('wording.first_name') }}</div>
      <div class="font-semibold text-lg mb-1 truncate">
        {{ firstName }}
      </div>
      <div class="text-xs">{{ $t('wording.last_name') }}</div>
      <div class="font-semibold text-lg truncate">
        {{ lastName }}
      </div>
    </div>
    <div
      class="
        steps__card__shadows
        grid grid-flow-col grid-cols-12 grid-rows-3
        gap-2
      "
    >
      <div class="col-span-3 row-span-2"></div>
      <div class="col-span-5 row-span-2 row-start-2">
        <div class="grid grid-cols-12 gap-2">
          <div class="steps__card__shadows__el col-span-5"></div>
          <div class="col-span-2"></div>
          <div class="steps__card__shadows__el col-span-5"></div>

          <div class="steps__card__shadows__el col-span-5"></div>
          <div class="steps__card__shadows__el col-span-2"></div>
          <div class="steps__card__shadows__el col-span-5"></div>

          <div class="steps__card__shadows__el col-span-12"></div>
        </div>
      </div>
      <div class="col-span-3 row-span-3 mx-3">
        <div class="steps__card__shadows__photo"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    firstName: {
      type: String,
      default: ''
    },
    lastName: {
      type: String,
      default: ''
    }
  },
  setup() {}
}
</script>
